<template>
  <div class="edit-trip-name">
    <div class="edit-trip-name--content">
      <v-flex xs12>
        <CRInput
          v-model="routeName"
          placeholder="Trip Name"
          label="Trip Name"
        />
      </v-flex>
    </div>
    <div class="cr-sidebar-dialog--btn-spacer"></div>
    <div class="cr-sidebar-dialog--action-btn" @click="submitForm">
      <span v-if="!loading">Save</span>
      <CRProgressCircular v-else :size="27" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { authComputed } from '@/state/helpers'
import { EventBus } from '@/utils/event-bus'

export default {
  data() {
    return {
      routeName: null,
      loading: false,
    }
  },
  computed: {
    ...authComputed,
    ...mapState('reservations', {
      reservationsCache: (state) => {
        return state.reservationsCache
      },
    }),
    reservationId() {
      return this.$route.params.id
    },
    reservation() {
      return this.reservationsCache[this.reservationId]
    },
  },
  mounted() {
    this.routeName = this.reservation.routeName
  },
  methods: {
    async submitForm() {
      this.loading = true
      const params = {
        tripId: this.reservation.tripId,
        payload: {
          routeName: this.routeName,
        },
      }
      await this.$store.dispatch('trips/editTripName', params)
      this.loading = false
      EventBus.$emit('refresh-detail')
      this.$store.dispatch('app/closeDialog')
    },
  },
}
</script>

<style lang="scss" scoped>
.edit-trip-name {
  height: 100%;
  width: 500px;

  &--content {
    margin: 20px 48px;

    p {
      margin: 0 0 4px 0;
    }
  }
}
</style>
